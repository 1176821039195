import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'

export default () => (
  <Layout>
    <section className="section-thanks">
        <div className="thanks-wrap">
          <h1>Your request has been submitted!</h1>
          <p>We will get in touch with you shortly.</p>
          <p className="thank-you">Thank You!</p>
          <br />
          <Link to="/" className="back-to-home">
                Back to Home
            </Link>
        </div>
    </section>
  </Layout>
)
